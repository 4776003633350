/* MonthPage.css */ 
body{
  overflow-y: scroll;
}

#DayOfMonth{
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    flex-direction: row;
    font-weight: bolder;
    border: 1px solid #000000;
    margin: 5px;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-right:20px;
    margin-left:20px;
    &:hover {
      box-shadow: 0 8px 8px rgba(105, 105, 105, 0.3);
    }
      &.current-day {
        box-shadow: 0 4px 4px rgba(99, 99, 99, 0.2);
      &:hover {
        box-shadow: 0 8px 8px rgba(105, 105, 105, 0.3);
      }
    }
}


