/* start css */
.home-page {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.text-field-box {
    display: flex;
    flex-direction: row;
    margin: 10px;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    align-content: center;
}

h2 {
    color: #959595;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.welcome {
    display: flex;
    flex-direction: row;
}

@keyframes smoothMove {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}

.animationText {
    animation: smoothMove 4s ease-in-out infinite;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }
}

.shake {
    animation: shake 0.8s ease-in-out infinite;
}

@keyframes reShake {

    0%,
    100% {
        transform: scaleX(-1) translateX(0);
    }

    50% {
        transform: scaleX(-1) translateX(5px);
    }
}

.reShake {
    transform: scaleX(-1);
    animation: reShake 0.8s ease-in-out infinite;
}

.buttonLanguage {
    position: fixed;
    top: 20px;
    left: 20px;
}

.banner {
    height: 40dvh;
    /* border: #804a4a 1px solid; */
}

.field {
    /* border: #959595 1px solid; */
    min-height: 40dvh;
}

.signUp {
    display: flex;
    flex: 1;
}