.day-box {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    border: 1px solid #0f1a25;
    margin: 5px;  
    align-items: center;
    justify-content: space-around;
    background-color: #2f5885;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    padding: 10px;
  }
  
  .day-box:hover {
    background-color: #2f5985c2;
  }
  
  .containerTimePicker{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25;
  }

  .TimePicker{
    background-color: #252525;
    width: 100px;
  }