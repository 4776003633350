/* BoxOfArrShiftsInDay.css */

.BoxOfArrShiftsInDay{
  display: flex;
  flex: 1;
  flex-direction: column;
}

#ShiftOneDetail {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #ffffffd0;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  font-size: smaller;
}

@media screen and (min-width: 800px) {
  #ShiftOneDetail{
    font-size: medium;
  }
}


.ShiftBox{
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  &&:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
}

.UserShiftDetails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;  
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.detailsGroup{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}

.ShiftNotFinish{
  border: 2px solid #502323;
}