body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
    background-color:#1E1F36;
}
.logo {
    mix-blend-mode: multiply;
    height: 40vh;
    max-width: 100dvw;
    /* width: 40vh; */
    /* background-size: 0 */
    border-radius: 100%;
}
.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.title {
    color: #fff;
    font-weight: bold;
    font-size: large;
    text-align: center;
    margin: 10px;
}
h1,h2,h3,h4,h5,h6{
    color: #ffffff85;
}
.remove {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #502323;
    background-color: #252525;
    color: #fff;
    border-radius: 5px;
    margin: 5px;
    height: 20px;
    width: 20px;
    font-size: 50%;
    cursor: pointer;

    &:hover {
        background-color: #502323;
        color: white; /* Change text color on hover */
    }
}