.users-page {
  padding-inline: 20px;
  padding-bottom: 20px;
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  font-family: Arial, sans-serif;
  transition: padding-inline 0.3s ease, padding-bottom 0.3s ease;
}

@media (max-width: 700px) {
  .users-page {
    padding-inline: 0px;
    padding-bottom: 10px;
    /* Optional: Adjust padding-bottom for small screens */
  }
}


.user-row {
  transition: background-color 0.5s;
  cursor: pointer;
}

.user-row:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  /* align-self: center; */
}

.status.true {
  background-color: #9ccfa8;
  box-shadow: 0px 4px 6px rgba(0, 255, 234, 0.3);

  &:hover {
    background-color: #61bb76;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  }
}

.status.false {
  background-color: #bd7075;
  box-shadow: 0px 4px 6px #eb5a5a4d;

  &:hover {
    background-color: #b43844;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  }
}

.status.loading {
  background-color: #f2f2f2;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: pulse 0.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

.TableContainer {
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 10px;
}

.TableContainer::-webkit-scrollbar {
  height: 8px;
}

.TableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.TableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}