.detail{
    margin: 10px;
    width: 150px;
    text-align: start;
    font-weight: bolder;
    flex-wrap: wrap;
}

.continuer-detail{
    display: flex;
    flex-direction: row;
    margin: 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
}

.admin-promise{
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}